<template>
  <div class="mx-8 my-8">

    <h1 class="text-3xl mb-5">Empresas</h1>

    <div class="grid grid-cols-12 gap-6 mb-3">
      <div class="col-span-12 md:col-span-3 mb-1 mt-1">
          <select v-model="user" id="user" name="user" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <option :value="null" >Todos os usuários</option>
            <option v-for="u in users" :key="u._id" :value="u._id" >{{ u.name }}</option>
          </select>
      </div>
    
      <div class="col-span-12 md:col-span-2 mb-1 mt-2">
        <button @click="updateFiltro" class="bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
      </div>
      <div class="col-span-12 md:col-span-2 mb-1 mt-2">
        <a target="_blank" :href="`${url_api}/v1/${route}/exportar/empresas?user=${user}&token=${$store.state.token}`" type="button" class="bg-blue-800 hover:bg-blue-900 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Download </a>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <h4 class="font-bold text-base mb-2">{{ total}} registros cadastrados </h4>
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nome
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    CNPJ
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Cadastro
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Previsão
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Pessoas
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Usuário
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="item in list" :key="item._id">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.nome ? item.nome : ''}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.cnpj ? item.cnpj : ''}}
                      </div>
                    </div>
                  </td>

                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.data | moment("DD/MM/YYYY")}}
                      </div>
                    </div>
                  </td>

                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.dataPrevisao | moment("DD/MM/YYYY")}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.pessoas ? item.pessoas : '0'}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.user ? item.user.name : ''}}
                        <button @click="modalMigrarEmpresa(item)" class="text-white text-sm hover:bg-green-500 bg-green-700 rounded py-1 px-2 mx-1">
                          Migrar empresa
                        </button>
                      </div>
                    </div>
                  </td>
                 
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <router-link 
                      :to="`/${route}/form/${item._id}`" 
                      class="text-blue-500 rounded py-1 px-2">
                      Editar
                    </router-link>
                    <button @click="remove(item._id)" class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1">
                      Excluir
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
    </div>
    <modal name="migrarEmpresa" :adaptive="true" :height="'auto'">
      <div class="px-5 py-5">
        <h2 class="text-lg mb-2 font-bold mb-2">Migrar empresa {{ migrar && migrar.empresa && migrar.empresa.nome ? migrar.empresa.nome : '' }}</h2>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12">
            <label for="user" class="block text-sm font-medium">Usuário</label>
            <select v-model="migrar.user" id="user" name="user" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option v-for="u in users" :key="u._id" :value="u._id" >{{ u.name }}</option>
            </select>
          </div>
          <div class="col-span-12">
            <button @click="migrarEmpresa()" class="text-white text-base hover:bg-green-500 bg-green-700 rounded py-1 px-4">
              Migrar
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: 'empresas',
      list: [],
      mes: '',
      ano: '',
      user: null,
      users: [],
      migrar: { user: null, empresa: null },
      query: { page: 1, limit: 10, skip: 0  },
      perPage: 10,
      total: 0,
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { user: this.user, skip: this.query.skip, limit: this.query.limit, });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async modalMigrarEmpresa(empresa){
      this.migrar.empresa = empresa;
      if(empresa.user) {
        this.migrar.user = empresa.user._id;
      }
      this.$modal.show('migrarEmpresa');
    },

    async migrarEmpresa(){
      if(!this.migrar.empresa) return this.$vToastify.console.error();("Nenhuma empresa selecionada!");
      if(!this.migrar.user) return this.$vToastify.console.error();("Nenhuma usuário selecionado!");

      const req = await this.$http.put(`/v1/${this.route}`, { _id: this.migrar.empresa._id, user: this.migrar.user, migrar: true });
      if (req.data.success) {
          this.$vToastify.success("Salvo com sucesso!");
          this.$modal.hide('migrarEmpresa');
          this.migrar = { user: null, empresa: null };
          this.start();
      } else {
          this.$vToastify.error(req.data.err);
      }
    },
    
    async remove(id) {
      if (confirm("Tem certeza que deseja excluir esta empresa?")) {
        await this.$http.delete(`/v1/${this.route}/${id}`);
        this.$vToastify.success("Removido com sucesso!");
        this.start();
      }
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { user: this.user, page: this.query.page, limit: this.query.limit, skip: this.query.skip };
      this.$router.push({ path: `/${this.route}`, query: this.query });
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      this.$router.push({ path: `/${this.route}`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.user) this.query.user = this.$route.query.user;
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    await this.start();
  },
  async beforeMount() {
    const req = await this.$http.post('/v1/users/list', { all: true });
    this.users = req.data.data;

    if(this.$route.query.user) this.query.user = this.$route.query.user;
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>