<template>
    <div class="mx-1 md:mx-8 my-8">
        <div class="flex mb-5">
            <h1 class="text-2xl mt-2 ml-2">
                Notificações <small>(Aviso para consultores)</small>
            </h1>
        </div>
        
        <div class="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST" autocomplete="off">
                <div class="px-5 pb-4">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-4">
                            <label for="titulo" class="block text-sm font-medium">Nome</label>
                            <input v-model="form.titulo" type="text" name="titulo" id="titulo" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-4 mt-4">
                        <div class="col-span-12 md:col-span-6">
                            <label for="descricao" class="block text-sm font-medium">Descrição</label>
                            <textarea rows="2" v-model="form.descricao" type="text" name="descricao" id="descricao" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
                        </div> 
                    </div>

                    <div class="grid grid-cols-12 gap-4 mt-4">
                        <div class="col-span-12 md:col-span-2">
                            <label for="email" class="block text-sm font-medium">
                                <input type="checkbox" v-model="form.email" class="rounded-sm" id="email">
                                <span class="ml-1">
                                    Notificar por Email
                                </span>
                            </label>
                        </div> 
                        <div class="col-span-12 md:col-span-2">
                            <label for="popup" class="block text-sm font-medium">
                                <input type="checkbox" v-model="form.popup" class="rounded-sm" id="popup">
                                <span class="ml-1">
                                    Notificar por Pop-up
                                </span>
                            </label>
                        </div> 
                    </div>

                    <div class="grid grid-cols-12 gap-4 mt-4">
                        <div class="col-span-12 md:col-span-4">
                            <label for="avisar" class="block text-sm font-medium">Consultores para notificar</label>
                            <select v-model="form.avisar" id="avisar" name="avisar" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <option value="Consultor Específico">Consultor Específico</option>
                                <option value="Consultores Pagantes">Consultores Pagantes</option>
                                <option value="Todos os consultores">Todos os consultores</option>
                            </select>
                        </div> 
                        <div v-if="form.avisar === 'Consultor Específico'" class="col-span-12 md:col-span-4">
                            <label for="consultor" class="block text-sm font-medium">Usuário</label>
                            <select v-model="form.consultor" id="consultor" name="consultor" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <option v-for="u in users" :key="u._id" :value="u._id" >{{ u.name }}</option>
                            </select>
                        </div>
                    </div>
                  
                </div>
                
                <div class="grid grid-cols-2">
                    <div class="px-5 py-3 text-left sm:px-6">
                        <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </button>
                    </div>
                    <div class="px-5 py-3 text-right sm:px-6">
                        <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
                            Salvar
                        </button>
                    </div>
                </div>
                
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            empresas: [],
            route: 'notificacoes',
            users: [],
            form: {
                titulo: '',
                descricao: '',
                popup: false,
                email: true,
                avisar: null,
                consultor: null
            }
        }
    },
    methods: {
        async save() {
            if(!this.form.avisar) return this.$vToastify.error("Selecione os consultores para aviso!");

            const req = await this.$http.post(`/v1/${this.route}/avisosAdmin`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        }
    },
    async beforeMount() {
        const req = await this.$http.post('/v1/users/list', { all: true });
        this.users = req.data.data;
    },
}
</script>