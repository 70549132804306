<template>
    <div class="md:flex flex-col md:flex-row md:min-h-screen w-full" id="menu">
        <div class="flex flex-col w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-blue-800 flex-shrink-0">
            <div class="flex-shrink-0 px-8 py-4 flex flex-row items-center justify-between">
                <img class="h-24" :src="require('../assets/logo.png')" />
                <button class="rounded-lg md:hidden rounded-lg focus:outline-none focus:shadow-outline" @click="open = !open">
                    <svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6">
                    <path v-show="!open" fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clip-rule="evenodd"></path>
                    <path v-show="open" fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                </button>
            </div>
            <nav :class="{'block': open, 'hidden': !open}" class="flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto">

                <router-link active-class="bg-blue-200" to="/users" class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg dark-mode:bg-blue-700 dark-mode:hover:bg-blue-600 dark-mode:focus:bg-blue-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-blue-200 focus:bg-blue-200 focus:outline-none focus:shadow-outline" href="#">
                    Usúarios
                </router-link>

                <router-link active-class="bg-blue-200" to="/empresas" class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg dark-mode:bg-blue-700 dark-mode:hover:bg-blue-600 dark-mode:focus:bg-blue-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-blue-200 focus:bg-blue-200 focus:outline-none focus:shadow-outline" href="#">
                    Empresas
                </router-link>

                <router-link active-class="bg-blue-200" to="/transferencias" class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg dark-mode:bg-blue-700 dark-mode:hover:bg-blue-600 dark-mode:focus:bg-blue-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-blue-200 focus:bg-blue-200 focus:outline-none focus:shadow-outline" href="#">
                    Transferências titulares
                </router-link>

                <router-link active-class="bg-blue-200" to="/notificacoes" class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg dark-mode:bg-blue-700 dark-mode:hover:bg-blue-600 dark-mode:focus:bg-blue-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-blue-200 focus:bg-blue-200 focus:outline-none focus:shadow-outline" href="#">
                    Notificações
                </router-link>

                <router-link active-class="bg-blue-200" to="/solicitacao" class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg dark-mode:bg-blue-700 dark-mode:hover:bg-blue-600 dark-mode:focus:bg-blue-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-blue-200 focus:bg-blue-200 focus:outline-none focus:shadow-outline" href="#">
                    Solicitações
                </router-link>

                <router-link active-class="bg-blue-200" to="/pesquisa" class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg dark-mode:bg-blue-700 dark-mode:hover:bg-blue-600 dark-mode:focus:bg-blue-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-blue-200 focus:bg-blue-200 focus:outline-none focus:shadow-outline" href="#">
                    Pesquisa
                </router-link>

                <router-link active-class="bg-blue-200" to="/entregaveis" class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg dark-mode:bg-blue-700 dark-mode:hover:bg-blue-600 dark-mode:focus:bg-blue-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-blue-200 focus:bg-blue-200 focus:outline-none focus:shadow-outline" href="#">
                    Entregáveis
                </router-link>

                <hr class="mt-5" />

                <router-link active-class="bg-blue-200" to="/atividades" class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg dark-mode:bg-blue-700 dark-mode:hover:bg-blue-600 dark-mode:focus:bg-blue-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-blue-200 focus:bg-blue-200 focus:outline-none focus:shadow-outline" href="#">
                    Atividades
                </router-link>

                <router-link active-class="bg-blue-200" to="/logs" class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg dark-mode:bg-blue-700 dark-mode:hover:bg-blue-600 dark-mode:focus:bg-blue-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-blue-200 focus:bg-blue-200 focus:outline-none focus:shadow-outline" href="#">
                    Logs
                </router-link>

                <a @click="logout" class="mt-10 block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-blue-600 dark-mode:focus:bg-blue-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-blue-200 focus:bg-blue-200 focus:outline-none focus:shadow-outline" href="#">
                    <b>Sair</b>
                </a>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Menu',
    data() {
        return {
            open: false,
        }
    },
    methods: {
        logout() {
            localStorage.removeItem('token');
            this.$store.commit('logout');
            this.$router.push({path: '/login'});
        }
    },
}
</script>