<template>
  <div class="mx-8 my-8">


    <h1 class="text-2xl mb-5">Personalização do Site:</h1>

    <form v-if="siteConfig" action="#" method="POST">
        <div class="col-span-12 md:col-span-8">
            <div class="grid grid-cols-12 gap-3">
                <div class="col-span-12 md:col-span-6">
                    <label for="arte" class="block text-sm font-medium">Imagem da tela de login</label>
                    <small class="text-xs font-medium">Tamanho recomendado 1920x1080</small>
                    <vue-dropzone ref="doc" id="doc" class="w-full mt-2" :options="dropzoneOptions" v-on:vdropzone-success="update">
                    </vue-dropzone>
                </div>
                <div class="col-span-12 md:col-span-6">
                    <img v-if="siteConfig.imagem && siteConfig.imagem.mimetype" class="border-1 mt-14 h-60 rounded-md" :src="`${url_api}/upload?mimetype=${siteConfig.imagem.mimetype}&filename=${siteConfig.imagem.filename}&folder=empresas`" />
                </div>
            </div>
        </div>

        <div class="col-span-12 md:col-span-4">
            <label for="frase_site" class="block text-sm font-medium mt-2">Texto da página de login</label>
            <textarea rows="6" v-model="siteConfig.texto" type="text" id="frase_site" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder=""/>
        </div> 

        <div class="py-3 text-right">
            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                Salvar
            </button>
        </div>
    </form>

    <h1 class="text-3xl mb-5">Entregáveis</h1>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Arquivo Template
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Opção
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateROPA?token=${$store.state.token}`" class="underline">Template ROPA</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files,'templateROPA')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateRIPD?token=${$store.state.token}`" class="underline">Template RIPD</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateRIPD')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateConformidade?token=${$store.state.token}`" class="underline">Template Conformidade</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateConformidade')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateDiagostico?token=${$store.state.token}`" class="underline">Template Diagostico</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateDiagostico')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecifico?token=${$store.state.token}`" class="underline">Template Especifico</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecifico')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades?token=${$store.state.token}`" class="underline">Template Especifico Atividades</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades1?token=${$store.state.token}`" class="underline">Template Especifico Atividades 1</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades1')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades2?token=${$store.state.token}`" class="underline">Template Especifico Atividades 2</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades2')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades3?token=${$store.state.token}`" class="underline">Template Especifico Atividades 3</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades3')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades4?token=${$store.state.token}`" class="underline">Template Especifico Atividades 4</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades4')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades5?token=${$store.state.token}`" class="underline">Template Especifico Atividades 5</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades5')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades6?token=${$store.state.token}`" class="underline">Template Especifico Atividades 6</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades6')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades7?token=${$store.state.token}`" class="underline">Template Especifico Atividades 7</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades7')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades8?token=${$store.state.token}`" class="underline">Template Especifico Atividades 9</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades8')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades9?token=${$store.state.token}`" class="underline">Template Especifico Atividades 9</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades9')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades10?token=${$store.state.token}`" class="underline">Template Especifico Atividades 10</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades10')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades11?token=${$store.state.token}`" class="underline">Template Especifico Atividades 11</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades11')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades12?token=${$store.state.token}`" class="underline">Template Especifico Atividades 12</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades12')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades13?token=${$store.state.token}`" class="underline">Template Especifico Atividades 13</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades13')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades14?token=${$store.state.token}`" class="underline">Template Especifico Atividades 14</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades14')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoAtividades15?token=${$store.state.token}`" class="underline">Template Especifico Atividades 15</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoAtividades15')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templateEspecificoEmpresas?token=${$store.state.token}`" class="underline">Template Especifico Empresas</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templateEspecificoEmpresas')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-blue-600 cursor-pointer">
                        <a :href="`${url_api}/v1/entregaveis/template/templatePoliticaPrivacidade?token=${$store.state.token}`" class="underline">Template Política de Privacidade</a>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <input type="file" @change="uploadFile($event.target.files, 'templatePoliticaPrivacidade')" id="file" class="custom-file-input" >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  components: {
        vueDropzone,
  },
  data() {
    return {
      route: 'pesquisa',
      list: [],
      mes: '',
      ano: '',
      user: null,
      users: [],
      query: { page: 1, limit: 10, skip: 0  },
      perPage: 10,
      total: 0,
      siteConfig: null,
      dropzoneOptions: this.$http.getDropzoneConfig(
          `/upload`,
          'post',
      {
          maxFiles: 1,
          addRemoveLinks: true,
          capture: true,
          dictDefaultMessage: 'Clique ou arraste sua arte aqui',
          dictRemoveFile: 'Remover',
          acceptedFiles: 'image/*'
      }),
    }
  },
  methods: {
    async save() {
        const req = await this.$http.put(`/v1/configuracoes/`, this.siteConfig);

        if (req.data.success) {
            this.$vToastify.success("Salvo com sucesso!");
        } else {
            this.$vToastify.error(req.data.err);
        }
    },
    async update(file, response) {
            this.siteConfig.imagem = response.file;
    },
    async uploadFile (files, nome) {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("templateName", nome);
      await this.$http.post("/v1/entregaveis/template", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      this.$vToastify.success("Atualizando template!");
    },

    async start() {
      const reqConfig = await this.$http.get("/v1/configuracoes");
      console.log(reqConfig.data);
      this.siteConfig = reqConfig.data.siteConfig;
    }

  },
  async beforeMount() {
    this.start();
  },
}
</script>