<template>
    <div class="mx-1 md:mx-8 my-8">
        <div class="flex mb-5">
            <h1 class="text-2xl mt-2 ml-2">
                Usuários / {{ $route.params.id ? 'Editar' : 'Novo' }}
            </h1>
        </div>
        
        <div class="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST" autocomplete="off">
                <div class="px-5 pb-4">
                    <div class="grid grid-cols-12 gap-6">

                        <div class="col-span-12 md:col-span-6">
                            <label for="name" class="block text-sm font-medium">Nome</label>
                            <input v-model="form.name" type="text" name="name" id="name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>

                        <div class="col-span-12 md:col-span-3">
                            <label for="email" class="block text-sm font-medium">Telefone Celular</label>
                            <the-mask :mask="['(##) ####-####', '(##) #####-####']" v-model="form.phone" placeholder="(99) 99999-9999" type="text" name="phone" id="phone" autocomplete="off" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                        </div>

                        <div class="col-span-12 md:col-span-6">
                            <label for="email" class="block text-sm font-medium">Email</label>
                            <input v-model="form.email" type="text" name="email" id="email" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div> 
                        
                    </div>
                    
                    <div class="grid grid-cols-12 gap-6 mt-3">
                        <div v-if="!alterarPassword" class="col-span-12 md:col-span-4">
                            <button @click="alterarPassword = !alterarPassword" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-500">
                                Alterar password
                            </button>
                        </div>
                    </div>
                    <div v-if="alterarPassword" class="grid grid-cols-12 gap-6 mt-3">
                        <div class="col-span-12 md:col-span-12">
                            <label for="password" class="block text-sm font-medium">Senha</label>
                            <input autocomplete="new-password" v-model="form.password" type="password" name="password" id="password" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                    </div>

                    <div class="grid grid-cols-12 gap-6">

                        <div class="col-span-12 md:col-span-6">
                            <label for="plano" class="block text-sm font-medium">Plano</label>
                            <input v-model="form.plano" type="text" name="plano" id="plano" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>

                        <div class="col-span-12 md:col-span-3">
                            <label for="valor" class="block text-sm font-medium">Valor</label>
                            <input v-model="form.valor" type="number" name="valor" id="valor" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>

                        <div class="col-span-12 md:col-span-6">
                            <label for="observacoes" class="block text-sm font-medium">Observações</label>
                            <textarea v-model="form.observacoes" type="text" name="observacoes" id="observacoes" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
                        </div> 

                        <div class="col-span-12 md:col-span-6">
                            <label for="arte" class="block text-sm font-medium">Atualizar Documento</label>
                            <vue-dropzone ref="doc" id="doc" class="w-full mt-2" :options="dropzoneOptions" v-on:vdropzone-success="update">
                            </vue-dropzone>

                            <br>
                            Documento atual: <a target="_blank" v-if="form.documento && form.documento.mimetype" class="border-1 mt-14 h-60 rounded-md text-blue-700" :href="`${url_api}/upload?mimetype=${form.documento.mimetype}&filename=${form.documento.filename}&folder=empresas`">{{ form.documento.originalname }} </a>
                        </div>
                    </div>
                </div>
                
                <div class="grid grid-cols-2">
                    <div class="px-5 py-3 text-left sm:px-6">
                        <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </button>
                    </div>
                    <div class="px-5 py-3 text-right sm:px-6">
                        <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
                            Salvar
                        </button>
                    </div>
                </div>
                
            </form>
        </div>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    components: {
            vueDropzone,
    },
    data() {
        return {
            empresas: [],
            route: 'users',
            alterarPassword: this.$route.params.id ? false : true,
            form: {
                name: '',
                email: '',
                typeUser: 'normal',
                password: '',
                password2: '',
                plano: '',
                valor: '',
                observacoes: '',
                documento: {}
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `/upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste o documento aqui',
                dictRemoveFile: 'Remover',
            }),
        }
    },
    methods: {
        async update(file, response) {
            this.form.documento = response.file;
        },
        async save() {
            this.form.admin = this.form.typeUser === 'admin' ? true : false;
            
            const method = this.form._id ? 'put' : 'post';
            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        }
    },
    async beforeMount() {
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
            if(req.data.admin) this.form.typeUser = 'admin';
            else this.form.typeUser = 'normal';
        }

        const reqEmp = await this.$http.post("/v1/empresas/list");
        this.empresas = reqEmp.data.data;
    },
}
</script>