<template>
    <div class="mx-1 md:mx-8 my-8">
        <div class="flex mb-5">
            <h1 class="text-2xl mt-2 ml-2">
                Editar Empresa
            </h1>
        </div>
        
        <div class="mt-5 md:mt-0 md:col-span-2">
             <form action="#" method="POST">
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-8">
                                <label for="nome" class="block text-sm font-medium">Nome fantasia *</label>
                                <input  v-model="form.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12 md:col-span-4">
                                <label for="responsavel" class="block text-sm font-medium">Responsável pela Empresa </label>
                                <input v-model="form.responsavel" type="text" name="responsavel" id="responsavel" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>   
                        </div>
                    </div>
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-8">
                                <label for="razaoSocial" class="block text-sm font-medium">Razão Social *</label>
                                <input v-model="form.razaoSocial" type="text" name="razaoSocial" id="razaoSocial" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12 md:col-span-4">
                                <label for="cnpj" class="block text-sm font-medium">CNPJ *</label>
                                <the-mask :mask="['##.###.###/####-##']" masked v-model="form.cnpj" id="cnpj" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                            </div>   
                        </div>
                    </div>
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-3">
                                <label for="cep" class="block text-sm font-medium">Cep</label>
                                <input @blur="buscaCep" v-model="form.cep" type="text" name="cep" id="cep" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-7">
                                <label for="endereco" class="block text-sm font-medium">Endereço</label>
                                <input v-model="form.endereco" type="text" name="endereco" id="endereco" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-2">
                                <label for="numero" class="block text-sm font-medium">Número</label>
                                <input v-model="form.numero" type="text" name="numero" id="numero" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-5">
                                <label for="bairro" class="block text-sm font-medium">Bairro</label>
                                <input v-model="form.bairro" type="text" name="bairro" id="bairro" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-7">
                                <label for="complemento" class="block text-sm font-medium">Complemento</label>
                                <input v-model="form.complemento" type="text" name="complemento" id="complemento" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <label for="cidade" class="block text-sm font-medium">Cidade</label>
                                <input v-model="form.cidade" type="text" name="cidade" id="cidade" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <label for="estado" class="block text-sm font-medium">Estado</label>
                                <input v-model="form.estado" type="text" name="estado" id="estado" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                        </div>
                    </div>
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-3">
                                <label for="dataInicio" class="block text-sm font-medium">Data Início</label>
                                <datepicker v-model="form.dataInicio" :language="ptBR" name="dataInicio" input-class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                            </div>  
                            <div class="col-span-12 md:col-span-3">
                                <label for="dataPrevisao" class="block text-sm font-medium">Data Previsão Fim</label>
                                <datepicker v-model="form.dataPrevisao" :language="ptBR" name="dataPrevisao" input-class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                            </div>   
                            <div class="col-span-12 md:col-span-3">
                                <label for="horas" class="block text-sm font-medium">Horas contradas / previstas</label>
                                <input v-model="form.horas" type="number" name="horas" id="horas" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                        </div>
                    </div>
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-4">
                                <label for="telefone" class="block text-sm font-medium">Telefone</label>
                                <input v-model="form.telefone" type="text" name="telefone" id="telefone" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12 md:col-span-8">
                                <label for="email" class="block text-sm font-medium">Email</label>
                                <input v-model="form.email" type="email" name="email" id="email" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>   
                        </div>
                    </div>
                    <div class="px-5 pb-4">
                        <label for="telefone" class="block text-sm font-medium">Logo</label>
                        <small class="text-xs font-medium">Tamanho recomendado 400x200, PNG sem fundo</small>
                        <div class="grid grid-cols-12 gap-6 mt-3">
                            <div class="col-span-12 md:col-span-4">
                                <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="updateDoc">
                                </vue-dropzone>
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <img class="h-40 w-80" v-if="this.form.logo && this.form.logo.filename" :src="`${this.url_api}/upload?mimetype=image/jpeg&filename=${this.form.logo.filename}&folder=empresas`"/>
                            </div>
                        </div>
                    </div>
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12">
                            <div class="col-span-12 md:col-span-4 2xl:col-span-3">
                                <label class="block text-sm font-medium">Cor principal</label>
                                <sketch-picker v-model="form.colorPrincipal" />
                            </div>
                            <div class="col-span-12 md:col-span-4 2xl:col-span-3">
                                <label class="block text-sm font-medium">Cor secundária</label>
                                <sketch-picker v-model="form.colorSecundaria" />
                            </div>
                        </div>
                    </div>
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-3">
                                <label for="telefone" class="block text-sm font-medium">Login</label>
                                <input v-model="form.login" type="text" name="telefone" id="telefone" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12 md:col-span-3">
                                <label for="password" class="block text-sm font-medium">Senha</label>
                                <input placeholder="Mantenha vazio para manter atual" v-model="form.password" type="password" name="password" id="password" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>   
                        </div>
                    </div>
                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <router-link :to="`/painel/`" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar 
                            </router-link>
                            <button
                                type="button"
                                v-if="$route.params.id"
                                @click="remove()" 
                                class="inline-flex justify-center py-2 px-6 mx-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                Arquivar empresa
                            </button>
                        </div>
                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                    
                </form>
        </div>
    </div>
</template>


<script>
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import validarCNPJ from '@/core/validarCNPJ';
import { Sketch } from 'vue-color';
export default {
    components: {
        vueDropzone,
        Datepicker,
        'sketch-picker': Sketch
    },
    data() {
        return {
            ptBR: ptBR,
            route: 'empresas',
            form: {
                nome: '',
                razaoSocial: '',
                cnpj: '',
                email: '',
                telefone: '',
                cidade: '',
                cep: '',
                estado: '',
                endereco: '',
                numero: '',
                bairro: '',
                complemento: '',
                dataInicio: '',
                horas: '',
                dataPrevisao: '',
                responsavel: '',
                logo: '',
                colorPrincipal: '#0097B2',
                colorSecundaria: '#374151',
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste sua logo aqui',
                dictRemoveFile: 'Remover'
            }),
        }
    },
    methods: {
        async save() {

            if(!this.form.nome || !this.form.cnpj || !this.form.razaoSocial && validarCNPJ(this.form.cnpj)) {
                this.$vToastify.error("Nome fantasia, Razão Social e CNPJ são obrigatórios");
                return
            }

            this.form.colorPrincipal = this.form.colorPrincipal.hex ? this.form.colorPrincipal.hex: this.form.colorPrincipal;
            this.form.colorSecundaria = this.form.colorSecundaria.hex ? this.form.colorSecundaria.hex : this.form.colorSecundaria;

            const method = this.form._id ? 'put' : 'post';

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.push({path: `/empresas/`});
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        updateDoc(file, response) {
            this.form.logo = response.file;
        },
        async buscaCep() {
            if (!this.form.cep) return;
            
            const response = await this.$http.get(`/v1/cep/${this.form.cep}`);

            if (response.data.erro === undefined) {
                this.form.cidade = response.data.localidade;
                this.form.estado = response.data.uf;
                this.form.endereco = response.data.logradouro;
                this.form.bairro = response.data.bairro;
            }
        },
        async remove() {
            this.$confirm({
                title: 'Confirmação',
                message: `Tem certeza que deseja arquivar essa empresa?`,
                button: {
                    no: 'Não',
                    yes: 'Sim',
                },
                callback: async confirm => {
                    if (confirm) {
                        await this.$http.delete(`/v1/${this.route}/${this.$route.params.id}`);
                        this.$vToastify.success("Removido com sucesso!");
                        this.$router.back();
                    }
                }
            });
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
        }
    },
}
</script>