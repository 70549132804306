<template>
    <div class="mx-8 my-8">
  
      <h1 class="text-3xl mb-5">Solicitações Transferências titulares</h1>
  
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <h4 class="font-bold text-base mb-2">{{ total}} registros cadastrados </h4>
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Transferir
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Nome
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Empresa CNPJ
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Telefone 
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Usuário
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Transferido
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id">
                    <td class="px-2 py-3">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{item.empresa && item.empresa.nome ? item.empresa.nome : ''}}
                        </div>
                      </div>
                    </td>
  
                    <td class="px-2 py-3">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{item.nome ? item.nome : ''}}
                        </div>
                      </div>
                    </td>
  
                    <td class="px-2 py-3">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{item.empresaNome ? item.empresaNome : ''}}<br/> 
                          {{item.cnpj ? item.cnpj : ''}}
                        </div>
                      </div>
                    </td>
  
                    <td class="px-2 py-3">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{item.telefone ? item.telefone : ''}}
                        </div>
                      </div>
                    </td>
  
                    <td class="px-2 py-3">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{item.email ? item.email : ''}}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-3">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{item.usuario && item.usuario.name ? item.usuario.name : ''}}<br/>
                          {{item.usuario && item.usuario.email ? item.usuario.email : ''}}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-3">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{item.transferido ? 'Sim transferido' : 'Não transferido'}}<br/>
                          <button v-if="!item.transferido" @click="marcarTransferido(item._id)" class="text-white hover:bg-blue-500 bg-blue-700 rounded mt-1 py-1 px-3">
                            Marcar como transferido
                          </button>
                        </div>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        route: 'empresas',
        list: [],
        mes: '',
        ano: '',
        user: null,
        users: [],
        query: { page: 1, limit: 10, skip: 0  },
        perPage: 10,
        total: 0,
      }
    },
    methods: {
  
      async start() {
        const listReq = await this.$http.post(`/v1/${this.route}/transferir/list`, { skip: this.query.skip, limit: this.query.limit, });
        this.list = listReq.data.data;
        this.total = listReq.data.total;
      },
  
      updateFiltro(){
        this.query.page = 1;
        this.query.limit = 10;
        this.query.skip = 0;
  
        this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip };
        this.$router.push({ path: `/${this.route}/transferir/list`, query: this.query });
      },

      async marcarTransferido(id){
        if (confirm("Tem certeza que deseja marcar como transferido?")) {
          await this.$http.put(`/v1/${this.route}/transferir`,{ _id: id, transferido: true});
          this.$vToastify.success("Salvo com sucesso!");
          this.start();
        }
      },
  
      async setPage(page) {
        const skip = (page - 1) * this.perPage;
        const limit = this.perPage;
  
        this.query.page = page;
        this.query.skip = skip;
        this.query.limit = limit;
  
        this.$router.push({ path: `/${this.route}`, query: this.query });
      }
    },
    async beforeRouteUpdate(to, from, next) {
      next();

      if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
      if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
      if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);
  
      await this.start();
    },
    async beforeMount() {
        
      if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
      if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
      if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);
  
      this.start();
    },
  }
  </script>