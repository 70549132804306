<template>
    <div class="mx-1 md:mx-8 my-8">
        <div class="flex mb-5">
            <h1 class="text-2xl mt-2 ml-2">
                Indicações
            </h1>
        </div>
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Nome
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Email
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Data Cadastro
                        </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="item in indicados" :key="item._id">
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="flex items-center">
                                <div class="text-sm text-gray-500">
                                    {{item.name ? item.name : ''}}
                                </div>
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="flex items-center">
                                <div class="text-sm text-gray-500">
                                    {{item.email ? item.email : ''}}
                                </div>
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="flex items-center">
                                <div v-if="item.data" class="text-sm text-gray-500">
                                    {{ item.data | moment("DD/MM/YYYY") }}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {

  data() {
    return {
        indicados: []
    }
  },
  methods: {

    async start() {
        const indicadosReq = await this.$http.post('/v1/users/listIndicados',{ all: true, consultor: this.$route.params.id });
        this.indicados = indicadosReq.data.data; 
    },

  },
  async beforeMount() {
    this.start();
  },
}
</script>