import Vue from 'vue'
import VueToastify from "vue-toastify";
import moment from 'vue-moment';
import App from './App.vue'
import './assets/styles/index.css'
import http from './services/http';
import Pagination from 'vue-pagination-2';
import VModal from 'vue-js-modal';
import VueTheMask from 'vue-the-mask';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

Vue.component('pagination', Pagination);
Vue.use(VModal, { componentName: 'modal' })
Vue.use(VueToastify);
Vue.use(moment);
Vue.use(VueTheMask);
Vue.config.productionTip = false
Vue.prototype.$http = http;

import router from './router'
import store from './store';

Vue.mixin({
  data() {
    return {
      api: '',
      url_api : process.env.VUE_APP_API,
      jwt: '',
    }
  },
  methods: {
    hasAccess(roles) {
      if (roles.indexOf(store.state.user.role) > -1) {
        return true;
      }

      return false;
    },
    invertColor: function (hex, bw) {
      if (hex) {
        if (hex.indexOf('#') === 0) {
          hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        var r = parseInt(hex.slice(0, 2), 16),
            g = parseInt(hex.slice(2, 4), 16),
            b = parseInt(hex.slice(4, 6), 16);
        if (bw) {
            // http://stackoverflow.com/a/3943023/112731
            return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                ? '#000000'
                : '#FFFFFF';
        }
        // invert color components
        r = (255 - r).toString(16);
        g = (255 - g).toString(16);
        b = (255 - b).toString(16);
        // pad each with zeros and return
        return "#" + this.padZero(r) + this.padZero(g) + this.padZero(b);
      } else {
        return "#fff";
      }
    }
  },
  beforeMount() {
    this.api = process.env.VUE_APP_API;
    this.jwt = localStorage.getItem("token");
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
