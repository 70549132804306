import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    redirect: '/users',
  },

  {
    path: '/empresas',
    name: 'Empresas',
    component: () => import('../views/empresas/index.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/empresas/form/',
    name: 'EmpresasEdit',
    component: () => import('../views/empresas/form.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/empresas/form/:id',
    name: 'EmpresasEdit',
    component: () => import('../views/empresas/form.vue'),
    meta: {requiresLogin: true},
  },

  {
    path: '/transferencias',
    name: 'Transferencias',
    component: () => import('../views/transferencias/index.vue'),
    meta: {requiresLogin: true},
  },

  {
    path: '/solicitacao',
    name: 'Solicitacao',
    component: () => import('../views/solicitacao/index.vue'),
    meta: {requiresLogin: true},
  },

  {
    path: '/pesquisa',
    name: 'Pesquisa',
    component: () => import('../views/pesquisa/index.vue'),
    meta: {requiresLogin: true},
  },

  {
    path: '/notificacoes',
    name: 'Notificacoes',
    component: () => import('../views/notificacoes/index.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/notificacoes/form/',
    name: 'NotificacoesEdit',
    component: () => import('../views/notificacoes/form.vue'),
    meta: {requiresLogin: true},
  },
  
  {
    path: '/users',
    name: 'Usuarios',
    component: () => import('../views/users/index.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/users/form',
    name: 'UsuariosForm',
    component: () => import('../views/users/form.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/users/form/:id',
    name: 'UsuariosEdit',
    component: () => import('../views/users/form.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/users/indicacoes/:id',
    name: 'UsuariosIndicacoes',
    component: () => import('../views/users/indicacoes.vue'),
    meta: {requiresLogin: true},
  },

  {
    path: '/atividades',
    name: 'Atividades',
    component: () => import('../views/atividades/index.vue'),
    meta: {requiresLogin: true},
  },

  {
    path: '/logs',
    name: 'Logs',
    component: () => import('../views/logs/index.vue'),
    meta: {requiresLogin: true},
  },

  {
    path: '/entregaveis',
    name: 'Entregaveis',
    component: () => import('../views/entregaveis/index.vue'),
    meta: {requiresLogin: true},
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const hasToken = localStorage.getItem('token');
  if (hasToken && !store.state.isLogged) {
    store.commit('login', JSON.parse(localStorage.getItem('user')));
    next();
  } else if (to.meta.requiresLogin && !store.state.isLogged) {
    next({name: 'Login'});
  } else if (to.name === 'Login' && hasToken && store.state.isLogged) {
    next(from);
  } else {
    next();
  }
});

export default router;
